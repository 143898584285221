



a:link, a:visited {
  color: inherit;
  text-decoration: none;
  
}


body{
	font-family: "Raleway";
	margin-bottom: 40px;
	color: #444;
  font-size: 14px;
}

#app-header{
  padding: 8px 0;
    display: flex;
    justify-content: center;
    font-family: 'Audiowide', cursive;
    /*transform: scale(0.7);*/
}

.lobito{ padding-right: 20px; }
.lobito img{ height: 36px; }

.ikerhurtado{
  font-size: 2em;
  text-align: center
}

.software-engineer{
  display: none;
}

#app-menu{
  border-radius: 6px;
  width: 100%;
  padding: 3px;
  display: inline-block;
  text-align: center;
  color: white;
  background-color: #444
  }

#app-menu .menu-selected{
  font-weight: bold;
  /* font-size: 1.1em;*/
}
  
#app-menu a{
  display: inline-block;
  padding: 0 20px;
}

#app-placeholder{
 margin-top: 40px;
}




.front-page{
  margin-top: 20px;

}

.about-me{
  padding: 0 60px;
  font-size: 1.1em;
  line-height: 160%;
}

@media only screen and (min-width: 501px) {
   /* #app-header{  transform: scale(1);  } */

  .ikerhurtado{ font-size: 2.5em; }

  .lobito img{ height: 50px; }
}

@media only screen and (min-width: 901px) {
  body {
    width: 900px;
    margin: 0 auto 60px;
  }

  #app-header{
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
  }

  /*.ikerhurtado{ font-size: 2.5em; }*/

  .lobito{ width: 24%; padding-left: 40px; padding-right: 0;}

  .software-engineer{
    display: block;
    font-size: 0.8em; line-height: 150%;
  }
}


/***** text content ****/

.text-content{
  line-height: 150%;

  
}

/* commonmark styles */

.text-content p{
  margin: 10px 0;
  
}

.text-content code{
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
}

.text-content pre{
  padding: 10px;
  background-color: #EEE;
  border: 1px #CCC solid;
  
}

.text-content pre > code{
 padding: 0;
    color: inherit;
    background-color: inherit;
    border-radius: 0;
}



.text-content blockquote, .text-content .quote{
  padding-left: 20px;
  font-style: italic;
  border-left: 2px #BBB solid;
  
}

.text-content a{

  text-decoration: underline dotted #AAA;
}

.text-content div.intro{
    color: #777;
    background-color: #DDD;
    padding:  10px 16px ;
    border-radius: 3px;
}

.text-content div.stressed{
    color: #00529B;
    background-color: #BDE5F8;
    padding: 10px 16px  ;
    /*border: 1px #00529B solid;*/
    border-radius: 3px;
}



.text-content div.moreinfo {
    color: #4F8A10;
    background-color: #DFF2BF;
    padding: 10px 16px ;
    border-radius: 3px;
}

.text-content div.note {
    color: #9F6000;
    background-color: #FEEFB3;
    padding: 10px 16px ;
    border-radius: 3px;
}

.text-content div.question {
    color: #D8000C;
    background-color: #FFD2D2;
    padding: 10px 16px;
    border-radius: 3px;
}


.text-content ul {
    display: block;
    list-style-type: disc;

    margin: 10px 0;
    
    padding-inline-start: 20px;
}

.text-content li {
    margin: 8px 0;
    
}

.more-info-link{
  font-weight: bold;
  font-style: italic;
}


.text-content__subtitle-1{
  font-size: 1.8em;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 16px;
}

.text-content__subtitle-2{
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 10px;
}

.text-content__subtitle-3{
  font-size: 1.1em;
  font-weight: bold
}

/*
.text-content a:link, .text-content a:visited {
  text-decoration-style:  ; 
  }*/

/**************************/




#Blog{
    display: block;
  }

.labels-box {
    display: none;
  }

.labels-box a{

display: block;
}

.entries-box{
}

.blog-entry{
  margin: 0 20px 35px;
}



.blog-entry .title{
  font-size: 1.6em;
  font-weight: bold;
}

.blog-entry .title a{
  text-decoration: none;
}

.blog-entry .desc{
  font-style: italic;
  font-size: 0.9em;
  margin: 6px;
}

.entry-tags-box{
  margin: 5px 0;
}


.entry-type-menu{
  padding: 20px;

}

.entry-type-menu a{
  display: inline-block;
  background-color: #EEE;
  border-radius: 3px;
  border: 1px #BBB solid;
  padding: 2px 10px;
  margin: 0 10px;
  
}

a.entry-type-menu-selected{
  font-weight: bold;
  background-color: #BBB;
}

.blog-entry-editor form{
  display: flex; 
  flex-direction: column;
}

.blog-entry-editor form > *{
  margin: 10px;
  padding: 10px;
}

.label{
  background-color: #EEE;
  color: #777;
  border-radius: 3px;
  border: 1px #CCC solid;
  padding: 0 3px;
  font-size: 0.8em;
  /*font-weight: bold*/
}


@media only screen and (min-width: 901px) {

  #Blog{
    display: flex;
      /*justify-content: center; */
      justify-content: space-between;
  }



  .labels-box {
    display: block;
    padding: 24px 0 0 40px ;
    width: 20%;
    line-height: 180%;

  }

  .entries-box{
    width: 80%;
  }

}


/** CV page ** */

.cv-page{
  display: flex;
  justify-content: center;
}

.cv-page > div{
  width: 80%;
}

/*
.job-info{
  font-size: 0.96em
}*/
.job-info > span{
  font-size: 0.8em
}

/** Projects ** */

.projects-page{
  display: flex;
  justify-content: center;
}

.projects-page > div{
  width: 80%;
}


table.project-features td{
  padding: 2px 10px;
  vertical-align: top;
}

table.project-features td:first-child{
text-transform: uppercase;
font-size: .9em;

}
